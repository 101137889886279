import { computed, ref } from 'vue'
import { useRuntimeConfig, useDisplay } from '#imports'
import { useDisplay } from 'vuetify'

const config = useRuntimeConfig()

export function useCheckBreakpoints() {
  const { mdAndUp } = useDisplay()

  const isMobile = config.public.isDev
    ? computed(() => !mdAndUp.value)
    : !mdAndUp.value

  return { isMobile }
}